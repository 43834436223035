import React, { useContext, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import { MdKeyboardArrowUp } from "react-icons/md";

const Faq = ({ question, answer }) => {
	return (
		<Col lg={6} className="my-1 mx-0 px-0 ">
			<Accordion>
				<Card className="border-0 bg-background">
					<Card.Header className="border-0  px-0 p-0 m-0">
						<ContextAwareToggle eventKey="0" className=" border-0 ">
							<div className="d-flex align-items-center ps-2">
								<h2 className="text-white fs-3">{question}</h2>
							</div>
						</ContextAwareToggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0">
						<Card.Body className="pt-md-3 bg-background">
							<Row>
								<Col>
									<span>{answer}</span>
								</Col>
							</Row>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</Col>
	);
};

export default Faq;

function ContextAwareToggle({ children, eventKey, callback }) {
	const currentEventKey = useContext(AccordionContext);
	const [turnArrow, setTurnArrow] = useState(false);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		callback && callback(eventKey);
		setTurnArrow(!turnArrow);
	});

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Card.Header
			className="border-0"
			style={{
				color: isCurrentEventKey ? "#141412" : "#141412",
				backgroundColor: isCurrentEventKey ? "#141412" : "#141412",
			}}
			onClick={decoratedOnClick}
		>
			<Container className="pe-0 ps-1" fluid={true}>
				<Row className="">
					<Col
						className="d-flex justify-content-start align-items-center ps-0"
						xs={11}
					>
						{children}
					</Col>
					<Col xs={1} className="d-flex px-0  justify-content-start">
						<MdKeyboardArrowUp
							className={`text-white fs-1 ${turnArrow ? "rotate-down" : ""}`}
						/>
					</Col>
				</Row>
			</Container>
		</Card.Header>
	);
}
