import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Col from "react-bootstrap/Col";
import { Container, Row } from "react-bootstrap";
import useOnScreen from "./use-on-screen";
import CountUp from "react-countup";
import { SafeHtmlParser } from "./safeHtmlParser";

const BumperBanner = ({ title, items, ctaButton }) => {
  const ref1 = useRef();
  const onScreen1 = useOnScreen(ref1, "0px");

  return (
    <section className="py-5 py-lg-6 bg-bumper">
      <Container ref={ref1} className="">
        <Row className="">
          <Col className="text-center pb-5">
            <h2>{title || "Split the cost of your repairs with Bumper"}</h2>
          </Col>
        </Row>
        <Row>
          {items &&
            items.map((item) => (
              <Col className="text-center" lg={3} key={uuidv4()}>
                <h3 className="text-white display-2">
                  {item.accidentRepairBumperNumberPrefix
                    ? item.accidentRepairBumperNumberPrefix
                    : ""}
                  {onScreen1 && (
                    <CountUp
                      start={10}
                      end={item.accidentRepairBumperNumberText}
                    />
                  )}
                  {item.accidentRepairBumperNumberPostfix
                    ? item.accidentRepairBumperNumberPostfix
                    : ""}
                </h3>
                <p className="fs-3">
                  <SafeHtmlParser htmlContent={item.accidentRepairBumperNumberHeading} />
                </p>
              </Col>
            ))}
        </Row>
        {ctaButton && ctaButton.url && (
          <Row>
            <Col className="text-center pt-5">
              <a
                className="fs-4 inter-bold black-link "
                rel="noreferrer"
                href={ctaButton.url}
                target={ctaButton.target ?? "_self"}
              >
                {ctaButton.title}
              </a>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default BumperBanner;
