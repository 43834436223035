import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import BumperBanner from "../../components/bumber-banner";
import ImageSideContainer from "../../components/image-side-container";
import Faq from "../../components/faq-accordian";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const ServicingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: file(relativePath: { eq: "icon.png" }) {
        publicURL
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "servicing" }) {
        slug
        carServicingFields {
          carServicingBanner {
            carServicingBannerHeading
            carServicingBannerBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          carServicingContentSection {
            carServicingContentHeading
            carServicingContentText
          }
          carServicingLeftContentRightImage {
            carServicingLeftHeading
            carServicingLeftContent
            carServicingLeftButton {
              title
              target
              url
            }
            carServicingRightImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          carServicingLeftImageRightContent {
            carServicingRightContentHeading
            carServicingRightContentText
            carServicingRightContentButton {
              title
              target
              url
            }
            carServicingLeftImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          carServicingBumper {
            carServicingBumperHeading
            carServicingBumperNumbers {
              accidentRepairBumperNumberHeading
              accidentRepairBumperNumberPostfix
              accidentRepairBumperNumberPrefix
              accidentRepairBumperNumberText
            }
            carServicingBumperButton {
              title
              target
              url
            }
          }
          carServicingForm {
            carServicingFormHeading
          }
          carServicingFaqSection {
            carServicingFaqSectionHeading
            carServicingFaqSectionDescription
            carServicingFaqs {
              carServicingQuestion
              carServicingAnswer
            }
          }
          carServicingSchema {
            carServicingSchemaDescription
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, carServicingFields, slug },
  } = data;

  const {
    carServicingBanner,
    carServicingContentSection,
    carServicingLeftContentRightImage,
    carServicingLeftImageRightContent,
    carServicingBumper,
    carServicingForm,
    carServicingFaqSection,
    carServicingSchema,
  } = carServicingFields;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
    mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
    description: `${carServicingSchema.carServicingSchemaDescription}`,
    logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
    name: "ECF Car Care",
    url: "https://www.ecfcarcare.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
      image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
      name: "ECF Car Care",
      url: "https://www.ecfcarcare.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.ecfcarcare.co.uk/reviews",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "ECF Car Care",
        sameAs: "https://www.ecfcarcare.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "What We Do",
        item: {
          url: `${siteUrl}/what-we-do`,
          id: `${siteUrl}/what-we-do`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Servicing",
        item: {
          url: `${siteUrl}/what-we-do/servicing`,
          id: `${siteUrl}/what-we-do/servicing`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {carServicingBanner && (
          <section>
            <ServiceHero
              title={
                <span>
                  <SafeHtmlParser
                    htmlContent={carServicingBanner?.carServicingBannerHeading}
                  />
                </span>
              }
              backgroundImage={
                carServicingBanner.carServicingBannerBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                carServicingBanner.carServicingBannerBackgroundImage.node
                  ?.altText
              }
            />
          </section>
        )}
        {carServicingContentSection &&
          !checkPropertiesForNull(carServicingContentSection, [
            "carServicingContentHeading",
          ]) && (
            <section className="pt-5  pt-lg-8 position-relative">
              <Container>
                <Row>
                  <Col className="position-relative">
                    <h2 className="text-center pb-5">
                      {carServicingContentSection.carServicingContentHeading}
                    </h2>

                    <SafeHtmlParser
                      htmlContent={
                        carServicingContentSection?.carServicingContentText
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}

        {carServicingLeftImageRightContent &&
          !checkPropertiesForNull(carServicingLeftImageRightContent, [
            "carServicingRightContentHeading",
          ]) && (
            <div className="pb-5 pb-lg-8">
              <ImageSideContainer
                image={
                  carServicingLeftImageRightContent.carServicingLeftImage.node
                    ?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  carServicingLeftImageRightContent.carServicingLeftImage.node
                    ?.altText
                }
                title={
                  carServicingLeftImageRightContent.carServicingRightContentHeading
                }
                order="first"
                imagePadding=" pb-5 pb-lg-0    "
                text={
                  carServicingLeftImageRightContent.carServicingRightContentText
                }
                ctaButton={
                  carServicingLeftImageRightContent?.carServicingRightContentButton
                }
              />
            </div>
          )}

        {carServicingLeftContentRightImage &&
          !checkPropertiesForNull(carServicingLeftContentRightImage, [
            "carServicingRightImage",
          ]) && (
            <div className="pb-5 pb-lg-8">
              <ImageSideContainer
                image={
                  carServicingLeftContentRightImage.carServicingRightImage.node
                    ?.localFile.childImageSharp.gatsbyImageData
                }
                imageAlt={
                  carServicingLeftContentRightImage.carServicingRightImage.node
                    ?.altText
                }
                title={
                  carServicingLeftContentRightImage.carServicingLeftHeading
                }
                order="last"
                imagePadding=" pb-5 pb-lg-0    "
                text={carServicingLeftContentRightImage.carServicingLeftContent}
                ctaButton={
                  carServicingLeftContentRightImage?.carServicingLeftButton
                }
              />
            </div>
          )}

        {carServicingBumper &&
          !checkPropertiesForNull(carServicingBumper, [
            "carServicingBumperHeading",
            "carServicingBumperNumbers",
          ]) && (
            <BumperBanner
              title={carServicingBumper.carServicingBumperHeading}
              items={carServicingBumper.carServicingBumperNumbers}
              ctaButton={carServicingBumper.carServicingBumperButton}
            />
          )}
        <section id="book" className="pb-5  pt-5 py-lg-7">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <h2 className="text-center pb-4">
                  {carServicingForm.carServicingFormHeading ||
                    "BOOK YOUR APPOINTMENT"}
                </h2>
                <div
                  style={{ background: "#F9F9F9", borderRadius: "18px" }}
                  className="p-md-5 p-4"
                >
                  <MotForm page="Car Service Eastbourne" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {carServicingFaqSection &&
          !checkPropertiesForNull(carServicingFaqSection, [
            "carServicingFaqSectionHeading",
            "carServicingFaqs",
          ]) && (
            <section className="py-5 py-lg-8 bg-background">
              <Container className="">
                <Row className="justify-content-center">
                  <Col lg={10} className="text-center pb-6">
                    <h2 className="text-white pb-4">
                      {carServicingFaqSection.carServicingFaqSectionHeading}
                    </h2>
                    <p className="text-white">
                      <SafeHtmlParser
                        htmlContent={
                          carServicingFaqSection?.carServicingFaqSectionDescription
                        }
                      />
                    </p>
                  </Col>
                </Row>
                {carServicingFaqSection.carServicingFaqs &&
                  carServicingFaqSection.carServicingFaqs.length > 0 && (
                    <Row>
                      {carServicingFaqSection.carServicingFaqs.map(
                        (faqItem) => (
                          <Faq
                            question={faqItem.carServicingQuestion}
                            answer={
                              <div className="text-white">
                                <SafeHtmlParser
                                  htmlContent={faqItem?.carServicingAnswer}
                                />
                              </div>
                            }
                          />
                        )
                      )}
                    </Row>
                  )}
              </Container>
            </section>
          )}
      </Layout>
    </>
  );
};

export default ServicingPage;
